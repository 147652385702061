<template>
  <v-row class="ma-0">
    <v-col cols="12" class="pa-0">
      <v-tabs-items v-model="tabs_details_visita" class="tabs-general-vendedor--items">
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" class="pl-0 pb-0">
              <v-card flat tile class="fill-height">
                <v-card-text class="grey lighten-5 blue-grey--text">
                  <v-row class="px-6 py-1">
                    <template v-if="visita.cli_des">
                      <v-col cols="12" md="9">
                        <span class="caption text-uppercase d-block">
                          <v-icon size="18" color="icono" class="mt-n1">mdi-account-tie</v-icon>
                          Cliente
                        </span>
                        <span class="font-weight-bold" v-text="visita.cli_des+' ('+visita.co_cli+')'" />
                      </v-col>
                    </template>
                    <v-col cols="6" md="3">
                      <span class="caption text-uppercase d-block">
                        Pedido
                      </span>
                      <v-icon
                        size="18"
                        class="mt-n1"
                        color="icono"
                        left
                        v-text="visita.pedido === false ? 'mdi-text-box-remove-outline' : 'mdi-text-box-check-outline'"
                      />
                      <span
                        class="font-weight-bold"
                        v-text="visita.pedido === false ? 'Sin Pedido' : 'Pedido Registrado'"
                      />
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row class="px-6 py-1">
                    <v-col cols="6" md="3">
                      <span class="caption text-uppercase d-block">
                        Fecha
                      </span>
                      <v-icon size="18" color="icono" left class="mt-n1">mdi-calendar-month-outline</v-icon>
                      <span class="font-weight-bold">{{visita.fecha | smartDate}}</span>
                    </v-col>
                    <v-col cols="6" md="3">
                      <span class="caption text-uppercase d-block">
                        Inicio
                      </span>
                      <v-icon size="18" color="icono" left class="mt-n1">mdi-clock-outline</v-icon>
                      <span class="font-weight-bold" >{{horas_visitas.start}}</span>
                    </v-col>
                    <v-col cols="6" md="3">
                      <span class="caption text-uppercase d-block">
                        Final
                      </span>
                      <v-icon size="18" color="icono" left class="mt-n1">mdi-clock-outline</v-icon>
                      <span class="font-weight-bold" v-text="horas_visitas.end" />
                    </v-col>
                    <v-col cols="6" md="3">
                      <span class="caption text-uppercase d-block">
                        Duración
                      </span>
                      <v-icon size="18" color="icono" left class="mt-n1">mdi-alarm-check</v-icon>
                      <span class="font-weight-bold">{{duracion}}</span>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row class="px-6 py-1">

                    <v-col cols="6" md="4">
                      <span class="caption text-uppercase d-block pb-2">
                        Tipo de Visita
                      </span>
                      <v-chip
                        class="font-weight-bold text-uppercase"
                        color="purple-alert"
                        style="overflow: visible !important"
                        small
                        label
                        pill
                        dark
                      >
                        {{ visita.tipo_visita }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" md="8">
                      <div class="pb-2">
                        <span class="caption text-uppercase">
                          Distancia entre el cliente y el Vendedor:
                        </span>
                        <span class="font-weight-bold" v-text="distancia.metros + ' mts.'" />
                      </div>
                      <div class="d-flex align-center">
                        <v-chip
                          class="font-weight-bold text-uppercase"
                          :color=" distancia.metros < 100 ? 'success' : distancia.metros > 100 && distancia.metros < 300 ? 'warning' : distancia.metros > 300 ? 'red' : 'transparent'"
                          style="overflow: visible !important"
                          small
                          label
                          pill
                          dark
                        >
                          {{ distancia.status }}
                        </v-chip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              icon
                              color="blue-grey"
                              class="mx-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="tabs_details_visita++"
                            >
                              <v-icon
                                v-text="'mdi-map-marker-radius-outline'"
                              />
                            </v-btn>
                          </template>
                          <span>Ver Mapa</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-divider class="light"/>
                    </v-col>
                  </v-row>
                  <v-row v-if="visita.motivo" class="px-6 py-1">
                    <v-col cols="12">
                      <span class="caption text-uppercase d-block">
                        <v-icon small color="icono" class="mt-n1">mdi-comment-outline</v-icon>
                        Motivo
                      </span>
                      <span
                        class="font-weight-bold"
                        v-text="visita.motivo !== null ? visita.motivo : 'S/M'"
                      />
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-divider class="light"/>
                    </v-col>
                  </v-row>
                  <v-row class="px-6 py-1">
                    <v-col cols="12">
                      <span class="caption text-uppercase d-block">
                        <v-icon small color="icono" class="mt-n1">mdi-comment-outline</v-icon>
                        Comentario
                      </span>
                      <span
                        class="font-weight-bold"
                        v-text="visita.comentario !== null ? visita.comentario : 'S/C'"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="visita.fotos != null" cols="12" sm="12" class="pl-0 pt-0">
              <v-card flat class="px-6 pb-4">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-card-title class="caption text-uppercase d-block blue-grey--text text--darken-3 font-weight-bold pb-0 pt-2">
                      <v-icon size="16" color="blue-grey" class="mr-1">mdi-image-multiple-outline</v-icon>
                      Anaqueles
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" class="pt-2">
                    <v-slide-group
                    v-model="anaqueles"
                      class="pa-1"
                      show-arrows
                    >
                    <v-slide-item
                    v-for="(foto,i) in visita.fotos"
                        :key="'anaqueles-'+i"
                        class="shelf-images"
                      >
                        <v-card
                          width="100"
                          class="blue-grey lighten-4 mx-2"
                          flat
                        >
                        <v-img
                            :src="foto"
                            :lazy-src="foto"
                            height="100"
                            class="elevation-2"
                            @click="show(i)"
                            style="cursor: pointer !important"
                          />
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" class="pa-0">
              <v-toolbar
                color="grey lighten-4"
                flat
              >
                <v-toolbar-title class="pt-2 px-2">
                  <v-icon
                      class="mt-n1 mr-1"
                      @click="tabs_details_visita--"
                    >mdi-arrow-left</v-icon>
                    <span
                    >
                     <span>Regresar</span>
                    </span>
                </v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12">
              <v-card flat>
                <v-card-text class="pa-0">
                  <v-row>
                    <v-col cols="12" class="pr-0 pt-0" style="height: 29rem">
                       <view-maps :points="markers" :loadData="false" :centerable="center" :zoom="zoom_visita" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
          </v-col>
          </v-row>
          <!-- <v-row class="pb-12" v-if="visita.fotos != null">
            <v-col
              v-for="(foto,i) in visita.fotos"
              :key="'anaqueles-'+i"
              cols="6"
              md="3"
              class="elevation-2"
            >
              <v-img
                :src="foto"
                :lazy-src="foto"
                height="200"
                @click="show(i)"
                style="cursor: pointer !important"
              />
            </v-col>
          </v-row> -->
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <vue-easy-lightbox
      :imgs="visita.fotos"
      :visible="show_image"
      :index="index_current"
      @on-index-change="imgIndex"
      @hide="close"
    >
      <template v-slot:toolbar="{toolbarMethods}">
        <div class="toolbar-imagen">
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="toolbarMethods.zoomIn"
            >
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
          </div>
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="toolbarMethods.zoomOut"
            >
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
          </div>
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="downloadImagen"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="toolbarMethods.rotateLeft"
            >
              <v-icon>mdi-rotate-left</v-icon>
            </v-btn>
          </div>
          <div class="toolbar-imagen-btn">
            <v-btn
              color="white"
              plain
              :ripple="false"
              class="pa-1"
              style="min-width: 0px !important"
              @click="toolbarMethods.rotateRight"
            >
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </vue-easy-lightbox>
    <!-- <v-dialog v-model="show_image" :overlay-opacity="0.9" content-class="elevation-0">
      <v-btn icon @click="close" style="position: absolute; right:15px; top: 5px">
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
      <v-carousel v-model="index_current" hide-delimiters v-if="visita.fotos != null">
        <v-carousel-item
         v-for="(imagen,i) in visita.fotos"
          :key="imagen"
        >
          <v-img
            :src="imagen"
            height="100%"
            max-height="100hv"
            contain
          />
        </v-carousel-item>
      </v-carousel>
    </v-dialog> -->
  </v-row>
</template>
<script>
import { gmapApi } from 'gmap-vue';
import { getIconUrl } from '@/widgets/iconGenerator'
import moment from 'moment'
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
  name: 'DetailsVisita',
  props:{
    visita: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ViewMaps: () => import(
      /* webpackChunkName: "view-maps" */
      '@/widgets/ViewMaps'
    ),
    VueEasyLightbox
  },
  data: () => ({
    tabs_details_visita: 0,
    center: { lat: 8.955582, lng: -65.3220065 },
    zoom_visita: 15,
    show_image:false,
    index_current: 0,
    distancia:{
      metros: '',
      status: '',
    },
    icon: '',
    anaqueles: 0
  }),
  methods:{
    close() {
      this.show_image = false;
    },
    imgIndex(oldIdx, Idx) {
      this.index_current = Idx;
    },
    show(i) {
      this.index_current = i;
      setTimeout(() => {
        this.show_image = true;
      }, 90);
    },
    verPedido(item) {
      this.$emit('showPedido', {Numero: item});
    },
    downloadImagen() {
      let image_name = this.visita.fotos[this.index_current].split('/').pop();
      var imagenLink = document.createElement('a');
      imagenLink.href = this.visita.fotos[this.index_current];
      imagenLink.setAttribute('download', image_name);
      imagenLink.setAttribute('target', '_blank');
      document.body.appendChild(imagenLink);
      imagenLink.click();
      imagenLink.remove();
    },
  },
  computed:{
    google: gmapApi,
    markers () {
      console.log(this.visita);
      let color =this.distancia.metros < 100 ? '#4CAF50' : this.distancia.metros > 100 && this.distancia.metros < 300 ? '#FB8C00' : this.distancia.metros > 300 ? '#FF5252' : 'transparent'
      return [
        {
          position:{
            lat: parseFloat(this.visita.lat.trim()),
            lng: parseFloat(this.visita.lng.trim()),
          },
          icon:{
            markerColor: color,
            statusColor: color,
          },
        },
        {
          position:{
            lat: this.visita.lat_cliente !== null ? parseFloat(this.visita.lat_cliente.trim()) : 0,
            lng: this.visita.lng_cliente !== null ? parseFloat(this.visita.lng_cliente.trim()) : 0,
          },
          icon:{
            markerColor: this.visita.lat_cliente !== null ? '#00CAE3' : 'transparent',
            statusColor: this.visita.lat_cliente !== null ? '#00CAE3' : 'transparent',
          },
        }
      ]
    },
    icono() {
      return getIconUrl('#EDC10A', '#000000');
    },
    horas_visitas() {
      return {
        start: moment(`${this.visita.fecha} ${this.visita.hora_inicio}`).format('hh:mm a'),
        end: moment(`${this.visita.fecha} ${this.visita.hora_fin}`).format('hh:mm a')
      }
    },
    duracion () {
      const inicio = moment(`${this.visita?.fecha} ${this.visita?.hora_inicio}`);
      const fin = moment(`${this.visita?.fecha} ${this.visita?.hora_fin}`);
      const duracion = fin.diff(inicio)

      const hours = Math.floor(moment.duration(duracion).asHours()).toString()
      return `${ hours.length < 2 ? hours.padStart(2, '0') : hours }:${ moment.utc(duracion).format('mm:ss') }`
    },
  },
  watch:{
    visita (val) {
      this.$gmapApiPromiseLazy().then(() => {
        if(val.lat_cliente !== null && val.lng_cliente!==null) {
          var visita = new this.google.maps.LatLng(this.markers[0].position.lat, this.markers[0].position.lng);
          var cliente = new this.google.maps.LatLng(this.markers[1].position.lat, this.markers[1].position.lng);

          this.distancia.metros = this.google.maps.geometry.spherical.computeDistanceBetween(visita, cliente).toFixed(2);
          this.distancia.status = this.distancia.metros < 100 ? 'Dentro del rango establecido' :
                                  this.distancia.metros > 100 && this.distancia.metros < 300 ? 'Supera el rango establecido' :
                                  this.distancia.metros > 300 ? 'Fuera del Lugar' : null

        }
      });
      this.center = { lat: this.markers[0].position.lat, lng: this.markers[0].position.lng };
      this.zoom_visita = 8;
      this.tabs_details_visita = 0
    }
  },
  mounted () {
     this.$gmapApiPromiseLazy().then(() => {
        if(this.visita.lat_cliente !== null && this.visita.lng_cliente !== null){
          var visita = new this.google.maps.LatLng(this.markers[0].position.lat, this.markers[0].position.lng);
          var cliente = new this.google.maps.LatLng(this.markers[1].position.lat, this.markers[1].position.lng);

          this.distancia.metros = this.google.maps.geometry.spherical.computeDistanceBetween(visita, cliente).toFixed(2);
          this.distancia.status = this.distancia.metros < 100 ? 'Dentro del rango establecido' :
                                  this.distancia.metros > 100 && this.distancia.metros < 300 ? 'Supera el rango establecido' :
                                  this.distancia.metros > 300 ? 'Fuera del Lugar' : null

          this.center = { lat: this.markers[0].position.lat, lng: this.markers[0].position.lng };
          this.zoom_visita = 8;
        }
    });
  },
  filters:{
    hora(val){
      return val != "null" ? moment(val).format('hh:mm a') : '';
    }
  }
}
</script>
<!-- <style>
.shelf-images
</style> -->
